<template>
    <div class="container-fluid">
        <b-row class="d-flex justify-content-end">
            <b-col cols="auto">
                <span style="cursor:pointer;margin-right:-12px" @click="$bvModal.hide('sum-sub-modal')">
                    <i class="bi bi-x-lg"></i>
                </span>
            </b-col>
        </b-row>
        <div id="printable-window" class="dmx-gate-card mt-2 mb-2">
            <b-row>
                <b-col cols="12">
                    <div v-if="selectedSubscriptions" v-for="item in selectedSubscriptions" :key="item.id"
                        class="d-flex justify-content-between pt-2" id="example-1">
                        <div>
                            <p>
                                {{ item.gateName }}
                            </p>
                        </div>
                        <div>
                            <p>
                                {{ item.gateOwnerEmail
                                }}
                            </p>
                        </div>
                        <div>
                            <p>
                                {{ item.payment.totalAmount
                                }}
                            </p>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
        <b-row class="mt-2 mb-2">
                        <b-col cols="12">
                            <div class="float-left">
                                <b-button variant="warning" type="submit"
                                @click="$bvModal.hide('sum-sub-modal')">
                                    {{ $t('common_cancel') }}
                                </b-button>
                            </div>
                            <div class="float-right">
                                <b-button variant="dark" @click="printDiv()" class="noPrint">
                    <i class="bi bi-printer"></i>
                </b-button>
                            </div>
                        </b-col>
                    </b-row>
    </div>
</template>

<style></style>

<script>

export default {
    props: {
        selectedSubscriptions: []
    },
    methods: {
        printDiv() {
            var printContents = document.getElementById('printable-window').innerHTML;
            var originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            document.body.style.margin = "5px 5px 5px 5px";
            window.print();
            document.body.innerHTML = originalContents;
        }
    }

}
</script>