<template>
    <div class="container-fluid">
        <b-row class="d-flex justify-content-end">
            <b-col cols="auto">
                <span style="cursor:pointer;margin-right:-12px" @click="$bvModal.hide('multi-sub-modal')">
                    <i class="bi bi-x-lg"></i>
                </span>
            </b-col>
        </b-row>
        <p class="font-weight-bolder text-danger mt-2">
            * The selected Subscription will be added to all Gates in the list below
        </p>
        <b-row>
            <b-col sm="2">Duration: </b-col>
            <b-col sm="10">
                <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio-group id="radio-slots" v-model="subscriptionGateModel.duration" :options="subOptions"
                        :aria-describedby="ariaDescribedby" name="radio-options-slots">
                    </b-form-radio-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="2">
                <label>Discount: </label>
            </b-col>
            <b-col sm="4">
                <b-input-group prepend="%">
                    <b-form-input v-on:keyup="calculatePrice" type="number" min="0" max="100"
                        v-model="subscriptionGateModel.discount"></b-form-input>
                </b-input-group>
            </b-col>
        </b-row>
        <hr />
        <b-row class="mb-4">
            <b-col v-if="subscriptionTemplateList" v-for="item in subscriptionTemplateList" :key="'stl' + item.id"
                sm="12" lg="6" xl="6">
                <div :class="changeActiveColor(item.id)">
                    <span v-if="item.calculatedPrice > 0" class="price">
                        Price: {{ item.calculatedPrice }}<span v-if="item.currency">
                            {{ item.currency.currencyCode }} / Month
                        </span>
                    </span>
                    <span v-else class="price">
                        Price: FREE
                    </span>
                    <div class="pt-4">
                        <p class="text-danger" v-if="item.hasSub">
                            {{ item.hasSub.id }} - {{ item.hasSub.isCurrent }}
                        </p>
                        <h4 class="text-center">{{ item.name }}</h4>
                        <h6 v-if="item.description"><em>{{ item.description }}</em></h6>
                        <button v-if="!hasActiveSubscription" @click="addSubscriptionToGates(item.id, 30)"
                            class="btn btn-primary m-auto" type="button">
                            Add subscriptions to invoice
                        </button>
                    </div>
                </div>
            </b-col>
        </b-row>
        <hr />
        <div class="dmx-gate-card dmx-scroll-card mt-2 mb-2">
            <b-row>
                <b-col cols="12">
                    <div v-if="selectedGates" v-for="item in selectedGates" :key="item.id"
                        class="d-flex justify-content-between" id="example-1">
                        <div>
                            <p>
                                {{ item.id }}
                            </p>
                        </div>
                        <div>
                            <p>
                                {{ item.systemName }}
                            </p>
                        </div>
                        <div>
                            <p>
                                {{ item.gateOwnerUserName
                                }}
                            </p>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
        <b-row class="mt-2 mb-2">
            <b-col cols="12">
                <div class="float-left">
                    <b-button variant="warning" type="submit" @click="$bvModal.hide('multi-sub-modal')">
                        {{ $t('common_cancel') }}
                    </b-button>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<style scoped>
.dmx-scroll-card {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 200px;
    height: auto;
}
</style>

<script>
import subscriptionService from '@/services/subscriptionService';
export default {
    props: {
        selectedGates: [],

    },
    data() {
        return {
            subscriptionTemplateList: [],
            hasActiveSubscription: false,
            subscriptionGateModel: {
                subscriptionId: 0,
                gateList: [],
                gateId: 0,
                duration: 1,
                paymentMethod: 0,
                hasDirectDiscount: false,
                discount: 0
            },
            subOptions: [

                { text: 'One month', value: 1 },
                { text: 'Six months', value: 6 },
                { text: 'Twelve months', value: 12 },
            ],


        }
    },
    methods: {
        addSubscriptionToGates(subId, method) {

            this.subscriptionGateModel.subscriptionId = subId;
            this.subscriptionGateModel.paymentMethod = method;
            if(this.subscriptionGateModel.discount > 0){
                this.subscriptionGateModel.hasDirectDiscount = true;
            }
            this.selectedGates.forEach(x => {
                this.subscriptionGateModel.gateList.push(x.id);
            });

            subscriptionService.addSubscriptionToGate(this.subscriptionGateModel)
                .then((data) => {
                    this.$emit('closeaddsubgates');
                    console.log(data);
                })
                .catch(function (error) {
                    alert(error);
                });
        },
        calculatePrice() {
            if (this.subscriptionGateModel.discount > 0) {

                this.subscriptionTemplateList.forEach(x => {

                    let subtractedValue = x.price * (this.subscriptionGateModel.discount / 100);
                    x.calculatedPrice = x.price - subtractedValue;
                });
            } else {
                this.subscriptionTemplateList.forEach(x => {
                    x.calculatedPrice = x.price
                });
            }

        },
        getSubscriptionTemplateList() {
            subscriptionService.getSubscriptionTemplateListWithoutGate()
                .then((data) => {
                    this.subscriptionTemplateList = data;
                })
                .catch(function (error) {
                    alert(error);
                });
        },
        changeActiveColor(id) {
            if (id === this.activeSubscriptionId) {

                return "sub-box dmx-gate-card-active";
            }
            else {

                return "sub-box dmx-gate-card";
            }

        }
    },
    mounted() {
        this.getSubscriptionTemplateList();
    }

}
</script>