<template>
    <div class="text-center">
        <b-row>
            <b-col lg="8" sm="12" class="m-auto">
                <div class="dmx-gate-card">
                    <b-row no-gutters>
                        <b-row no-gutters align-v="center"  class="mt-2 col-8">
                            <b-col lg="4" sm="4" align="right" class="pr-2">
                                Gate specific discount:
                            </b-col>
                            <b-col lg="3" sm="3">
                                <b-input-group prepend="%">
                                    <b-form-input type="number" min="0" max="100" v-model="gate.discount"></b-form-input>
                                    <b-input-group-append>
                                        <b-button variant="success" @click="updateDiscount()">{{ $t('common_save')
                                            }}</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                        </b-row>
                        <b-col lg="4" sm="4" v-if="allGateSubscriptions.length" class="pt-3">
                            <button @click="goToPayment()" class="btn btn-primary btn-block" type="button">
                                Change subscription
                            </button>
                        </b-col>
                        <b-col lg="4" sm="4" v-else class="pt-3">
                            <button @click="goToPayment()" class="btn btn-primary btn-block" type="button">
                                Add subscription
                            </button>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
        <!--This section should show current Subscription and history of payments-->
        <b-row>
            <b-col lg="8" sm="12" class="m-auto">
                <table v-if="allGateSubscriptions"
                    class="table b-table table-striped table-hover table-bordered border">
                    <thead class="dmx-thead-block">
                        <tr>
                            <th scope="col">OrderId</th>
                            <th scope="col">Payment</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Name</th>
                            <th scope="col">Duration</th>
                            <th scope="col">Expires</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody v-if="allGateSubscriptions.length">
                        <tr v-for="item in allGateSubscriptions" :key="'agb' + item.id">
                            <td>{{ item.payment.orderId }}</td>
                            <td>{{ item.paymentMethodName }}</td>
                            <td>{{ item.payment.totalAmount }} {{ item.payment.currency.currencyCode }}</td>
                            <td>{{ item.subscription.name }}</td>
                            <td>{{ item.duration }}</td>
                            <td>{{ utcFormattedWOtime(item.subscriptionExpiryDateUtc) }}</td>
                            <td>
                                <label v-if="item.isCurrent && item.subscriptionExpiryDateUtc != null"
                                    class="badge badge-success">Active</label>
                                <label v-else-if="item.isCurrent && item.subscriptionExpiryDateUtc === null"
                                    class="badge badge-warning">Not started</label>
                                    <label v-else-if="item.paymentMethod === 10" class="badge badge-info">FREE</label>
                                <label v-else-if="!item.isCurrent" class="badge badge-warning">Inactive</label>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="7">No active subscriptions on this gate</td>
                        </tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import router from '@/router';
import subscriptionService from '@/services/subscriptionService';
import gateService from '@/services/gateService';
export default {
    props: {
        gate: {},
    },
    data() {
        return {
            allGateSubscriptions: []
        }
    },
    methods: {
        goToPayment() {
            gateService.updateGate(this.gate)
                .then(data => {
                    router.push({ name: 'Payment', params: { gateId: this.gate.id } })
                });

        },
        updateDiscount() {
            gateService.updateGate(this.gate)
                .then(data => {
                    this.makeToast(`Gate`, `Gate values has been saved.`);
                }).catch((err) => {
                    this.makeToast(`Gate`, `Unable to create gate. Err: ${err}`, 'danger');
                });
        },
        getGateSubscriptionList() {
            subscriptionService.getGateSubscriptionsById(this.gate.id)
                .then((data) => {
                    this.allGateSubscriptions = data;
                    if (this.allGateSubscriptions.some(x => x.isCurrent)) {
                        this.hasActiveSubscription = true;

                        let subobj = this.allGateSubscriptions.find(x => x.isCurrent);
                        if (subobj) {
                            this.activeSubscriptionId = subobj.subscriptionId;
                        }
                    }
                    if (this.allGateSubscriptions.some(x => x.subscriptionExpiryDateUtc === null)) {
                        this.notActivatedSubscription = true;
                    }

                })
                .catch(function (error) {
                    alert(error);
                });
        },
    },
    mounted() {
        if (this.gate.id > 0) {
            this.getGateSubscriptionList();
        }
    }
}
</script>