<template>
    <div class="container-fluid">

        <b-row class="d-flex justify-content-end mt-2 mr-2">
            <b-col cols="1">
                <b-button variant="primary" @click="$bvModal.hide('pay-sub-modal')">
                    <i class="bi bi-x-lg"></i>
                </b-button>
            </b-col>
        </b-row>

        <div class="dmx-gate-card mt-2 mb-2">
            <b-row>
                <b-col cols="12">
                    <div v-if="selectedSubscriptions" v-for="item in selectedSubscriptions" :key="item.id"
                        class="d-flex justify-content-between pt-2" id="example-1">
                        <div>
                            <p>
                                {{ item.gateName }}
                            </p>
                        </div>
                        <div>
                            <p>
                                {{ item.gateOwnerEmail
                                }}
                            </p>
                        </div>
                        <div>
                            <p>
                                {{ item.payment.totalAmount
                                }}
                            </p>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>

    </div>
</template>

<style></style>

<script>

export default {
    props: {
        selectedSubscriptions: []
    },
    methods: {
       
    }

}
</script>